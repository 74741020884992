<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadNotifications.length }} إشعار جديد</h3>
        <p class="opacity-75" @click="getNotification"> الإشعارات الغير مقروءة</p>
      </div>

      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li @click="openNtf(ntf, index)" v-for="(ntf, index) in unreadNotifications" :key="ntf.id" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex items-start">
              <feather-icon icon="PackageIcon" svgClasses=" text-warning stroke-current mr-1 h-6 w-6"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title text-warning" >{{ ntf.title }}</span>
                <small>{{ ntf.body }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.created_at) }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">

        <router-link to="/notifications">
         <span>عرض جميع الأشعارات</span>
         </router-link>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import moduleNotifications from '@/store/notifications/moduleNotifications.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from "vuex";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      unreadNotifications: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  methods: {
        ...mapActions("notifications", ["fetchNotifications", "readNotification"]),

    openNtf(notif, index){
      switch(notif.type)
      {
        case "order": {
          this.$router.push({ name: 'edit-order', params: { id: notif.data_id } })
        } break;
        case "transaction": {
           this.$router.push({ name: 'transactions'});
        }
      }
      notif.is_read?'':this.readNotification(notif.id);
      this.unreadNotifications.pop(index);

    },
    elapsedTime(startTime) {
        let x        = new Date(startTime)
        let now      = new Date()
        var timeDiff = now - x
        timeDiff    /= 1000

        var seconds = Math.round(timeDiff)
        timeDiff    = Math.floor(timeDiff / 60)

        var minutes = Math.round(timeDiff % 60)
        timeDiff    = Math.floor(timeDiff / 60)

        var hours   = Math.round(timeDiff % 24)
        timeDiff    = Math.floor(timeDiff / 24)

        var days    = Math.round(timeDiff % 365)
        timeDiff    = Math.floor(timeDiff / 365)

        var years   = timeDiff

        if (years > 0) {
          return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
        } else if (days > 0) {
          return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
        } else if (hours > 0) {
          return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
        } else if (minutes > 0) {
          return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
        } else if (seconds > 0) {
          return seconds + (seconds > 1 ? ' sec ago' : 'just now')
        }

        return 'Just Now'
    },
    getNotification(){
      this.fetchNotifications().then((response)=>{
        var unread = response.data.filter(n => !n.is_read); //filters unread notifications
        this.unreadNotifications = unread;
      })
    }
  },
  created(){
      if (!moduleNotifications.isRegistered) {
        this.$store.registerModule("notification", moduleNotifications);
        moduleNotifications.isRegistered = true;
      }
      this.getNotification()
  },
  mounted(){
    this.$root.$on('getNotification', () => {
      this.getNotification()
    })
  }

}

</script>

