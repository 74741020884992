<template>
  <div>
    <vs-button
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-dollar-sign"
      size="small"
      @click="popupActive = true"
      >تغيير سعر العملة
    </vs-button>
    <vs-popup
      class="holamundo"
      title="تغيير سعر الدولار"
      :active.sync="popupActive"
      ref="popupcontent"
    >
      <div class="vx-row flex-col">
        <div class="vx-col">
          <vx-input-group class="mb-base">
            <template slot="prepend">
              <div class="prepend-text bg-primary">
                <span>دولار</span>
              </div>
            </template>
            <vs-input disabled type="number" value="1.00" />
          </vx-input-group>
        </div>
        <div class="vx-col">
          <vx-input-group class="mb-base">
            <template slot="prepend">
              <div class="prepend-text bg-primary">
                <span>دينار</span>
              </div>
            </template>
            <vs-input type="number" v-model="rate" />
          </vx-input-group>
        </div>
      </div>
      <div class="vx-row justify-end">
        <vs-button
          color="primary"
          type="filled"
          class="mr-2 vx-col"
          @click="submit()"
          >تطبيق</vs-button
        >
        <vs-button
          color="primary"
          type="border"
          class="vx-col"
          @click="popupActive = false"
          >إلغاء</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      popupActive: false,
      rate: 0,
    };
  },
  methods: {
    ...mapActions("HomeStats", ["updateRate","fetchCurrencies"]),
    submit() {
      this.$vs.loading();
      this.updateRate({ rate: this.rate })
        .then((response) => {
           this.$vs.loading.close();
          this.popupActive = false;
          this.fetchCurrencies();
          this.successDialog(response.data.message);
        })
        .catch((error) => {
           this.$vs.loading.close();
          this.errorDialog(error);
        });
    },
  },
  mounted(){
    this.rate = localStorage.getItem("rate")
  },
  
};
</script>

<style>
</style>