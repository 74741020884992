/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [{
    url: "/home",
    name: "الرئيسية",
    slug: "home",
    icon: "HomeIcon"

  },
  {
    header: "المتجر",
    items: [

      {
        url: "/e-commerce/products",
        name: "المنتجات",
        slug: "products-list",
        icon: "PackageIcon"
      },
      {
        url: "/e-commerce/wishlist/products",
        name: "منتجات قوائم الامنيات",
        slug: "products-list",
        icon: "PackageIcon"
      },
      {
        url: "/e-commerce/offers",
        name: "العروض",
        slug: "offers",
        icon: "StarIcon"
      },
      {
        url: "/e-commerce/collections",
        name: "المجموعات",
        slug: "products-list",
        icon: "PackageIcon"
      },
      {
        url: "/e-commerce/orders",
        name: "الطلبات",
        slug: "orders",
        icon: "TruckIcon"
      },
      {
        url: "/coupons",
        name: "الكوبونات",
        slug: "coupons",
        icon: "PercentIcon"
      }
    ]

  },


  {
    header: "التقارير",
    items: [
      {
        name: 'تقارير النظام',
        icon: "BarChart2Icon",
        submenu: [
          {
            url: "/reports/products",
            name: "المنتجات",
            slug: "reports-products",
            icon: "ShoppingCartIcon",
          },

        ],
      }
    ],
  },
  {
    header: "الأرشيف",
    items: [{
      name: 'الأرشيف',
      icon: "ArchiveIcon",
      submenu: [{
          url: "/archive/orders",
          name: "الطلبات",
          slug: "archive-orders",
          icon: "ShoppingCartIcon",
        },
        {
          url: "/archive/products",
          name: "المنتجات",
          slug: "archive-products",
          icon: "GridIcon",
        },
        {
          url: "/archive/users",
          name: "المستخدمين",
          slug: "archive-users",
          icon: "UserIcon",
        },
        {
          url: "/archive/reps",
          name: "المندوبين",
          slug: "archive-reps",
          icon: "UserIcon",
        },
      ],

    }],
  },
  {
    header: "المستخدمون",
    items: [{
        url: "/reps",
        name: "المندوبين",
        slug: "Reps",
        icon: "UserIcon"
      },

    ],
  },
]
