/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/home",
    name: "الرئيسية",
    slug: "home",
    icon: "HomeIcon"

  },
  {
    url: "/stores-management/stores",
    name: "المتاجر",
    slug: "stores",
    icon: "ShoppingBagIcon",
  },
  {
    url: "/banners",
    name: "الإعلانات",
    slug: "banners",
    icon: "TabletIcon",
  },
  {
    url: "/notifications/manager",
    name: "مرسل الإشعارات",
    slug: "notif-manager",
    icon: "BellIcon",
  },
  {
    header: "Class-A",
    items: [
      {
        name: 'Class-A',
        icon: "TruckIcon",
        submenu: [
          {
            url: "/e-commerce/products",
            name: "المنتجات",
            slug: "products-list",
            icon: "PackageIcon"
          },
          {
            url: "/e-commerce/wishlist/products",
            name: "منتجات قوائم الامنيات",
            slug: "products-list",
            icon: "PackageIcon"
          }
          ,
          {
            url: "/e-commerce/categories",
            name: "التصنيفات",
            slug: "categories",
            icon: "PackageIcon"
          },
          {
            url:'/e-commerce/sub-category/view',
            name:"شجرة التصنيفات",
            slug: 'sub-category',
            icon:'PackageIcon',
          },
          {
            url:'/e-commerce/heighlites/view',
            name:"قسم هايلات",
            slug: 'heighlites',
            icon:'PackageIcon',
          },
          {
            url:"/e-commerce/colors",
            name:"إضافة ألوان",
            slug:"colors",
            icon: "PackageIcon"
          },
          {
            url:'/e-commerce/price-range/view',
            name:"إضافة نسبة الربح",
            slug:"price-range",
            icon: "PackageIcon"
          },
          {
            url: "/e-commerce/brands",
            name: "العلامات التجارية",
            slug: "brands",
            icon: "PackageIcon",
          },
          {
            url: "/e-commerce/offers",
            name: "العروض",
            slug: "offers",
            icon: "StarIcon"
          },
          {
            url: "/e-commerce/collections",
            name: "المجموعات",
            slug: "products-list",
            icon: "PackageIcon"
          },
          {
            url: "/e-commerce/orders",
            name: "الطلبات",
            slug: "orders",
            icon: "TruckIcon"
          },
        ]
      }
    ]

  },
  {
    header: "CLASS-B",
    items: [
      {
        name: 'Class-B',
        icon: "TruckIcon",
        submenu: [

      {
        url: "/stores-management/products",
        name: "المنتجات",
        slug: "stores-management-products",
        icon: "GridIcon",
      },
      {
        url: "/stores-management/orders",
        name: "الطلبات",
        slug: "stores-management-orders",
        icon: "ShoppingCartIcon",
      }
        ]
      }
    ],
  },
  {
    header: "المتجر",
    i18n: "Store",
    items: [
      {
        url: "/transactions",
        name: "المعاملات المالية",
        slug: "transactions",
        icon: "DollarSignIcon",
      },
      {
        url: "/coupons",
        name: "الكوبونات",
        slug: "coupons",
        icon: "PercentIcon"
      }
    ],
  },
  {
    header: "التقارير",
    items: [
      {
        name: 'تقارير النظام',
        icon: "BarChart2Icon",
        submenu: [
          {
            url: "/reports/reps",
            name: "المندوبين",
            slug: "reports-reps",
            icon: "ShoppingCartIcon",
          },
          {
            url: "/reports/transactions",
            name: "المعاملات المالية",
            slug: "reports-transactions",
            icon: "ShoppingCartIcon",
          },
          {
            url: "/reports/users",
            name: "العملاء",
            slug: "reports-users",
            icon: "ShoppingCartIcon",
          },
          {
            url: "/reports/products",
            name: "المنتجات",
            slug: "reports-products",
            icon: "ShoppingCartIcon",
          },

        ],
      }
    ],
  },
  {
    header: "الأرشيف",
    items: [
      {
        name: 'الأرشيف',
        icon: "ArchiveIcon",
        submenu: [
          {
            url: "/archive/orders",
            name: "الطلبات",
            slug: "archive-orders",
            icon: "ShoppingCartIcon",
          },
          {
            url: "/archive/products",
            name: "منتجات",
            slug: "archive-products",
            icon: "GridIcon",
          },
          {
            url: "/archive/brands",
            name: "العلامات التجارية",
            slug: "archive-brands",
            icon: "PackageIcon",
          },
          {
            url: "/archive/users",
            name: "المستخدمين",
            slug: "archive-users",
            icon: "UserIcon",
          },
          {
            url: "/archive/store-orders",
            name: "طلبات المتاجر",
            slug: "archive-store-orders",
            icon: "ShoppingCartIcon",
          },
          {
            url: "/archive/store-products",
            name: "منتجات المتاجر",
            slug: "archive-store-products",
            icon: "GridIcon",
          },
          {
            url: "/archive/sellers",
            name: "التجار",
            slug: "archive-sellers",
            icon: "UserIcon",
          },
          {
            url: "/archive/reps",
            name: "المندوبين",
            slug: "archive-reps",
            icon: "UserIcon",
          },
        ],

      }
    ],
  },
  {
    header: "المستخدمون",
    items: [
      {
        name: 'المستخدمون',
        icon: "UsersIcon",
        submenu:[

      {
        url: "/sellers",
        name: "التجار",
        slug: "sellers",
        icon: "UserIcon",
      },
      {
        url: "/reps",
        name: "المندوبين",
        slug: "Reps",
        icon: "UserIcon"
      },
      {
        url: "/users",
        name: "العملاء",
        slug: "Users",
        icon: "UsersIcon",
      },
        ]
      }


    ],
  },
]
